import React from "react";
import Pokegame from "./Pokegame";
import "./App.css";

function App() {
    return (
        <div className="App">
            <Pokegame />
        </div>
    );
}

export default App;
